import { useAppDispatch, useAppSelector } from 'app/hooks';
import { openModal } from 'common/slice/modalSlice';
import { getDistinctImpactAlerts } from 'helpers/playback';
import { ClockIcon } from '@heroicons/react/24/outline';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import { ProviewRating } from 'globals/enums';
import { Tooltip } from 'react-tooltip';
import { formatDate } from 'helpers/date';

const ProviewIndex = ({
  ratingModified,
  ratingModifiedAt,
  ratingModifiedReason,
  sessionID,
  alerts,
}) => {
  const dispatch = useAppDispatch();
  const { proviewRating, isEmbeddedView } = useAppSelector((state) => state.playbackVideo);

  const getRatingStyle = () => {
    switch (proviewRating) {
      case ProviewRating.Low:
        return 'bg-red-200 text-red-800';
      case ProviewRating.Medium:
        return 'bg-amber-200 text-amber-800';
      case ProviewRating.High:
        return 'bg-green-200 text-green-800';
      default:
        return 'bg-white text-black';
    }
  };

  const handleIndexUpdate = () => {
    dispatch(openModal({ type: 'proview-index' }));
  };

  return (
    <div className="flex flex-col gap-2 p-2">
      <div className="flex items-center gap-2">
        <div
          className={`font-medium py-2 px-6 capitalize text-xl rounded-full ${getRatingStyle()}`}
        >
          {proviewRating || '---'}
        </div>
        <div className="flex text-gray-500">
          {!isEmbeddedView && (
            <button
              data-testid="editRating-btn"
              disabled={isEmbeddedView}
              onClick={handleIndexUpdate}
            >
              <PencilSquareIcon className="w-4 h-4" />
            </button>
          )}
          {ratingModified && (
            <>
              <div data-tooltip-id={sessionID} className="flex items-center p-2 cursor-pointer">
                <ClockIcon className="w-4 h-4" />
                <div className="px-2 underline">Updated by Proctor</div>
              </div>
              <Tooltip className="alert-log-tooltip z-[1000]" id={sessionID} place="top">
                {formatDate(ratingModifiedAt)}
              </Tooltip>
            </>
          )}
        </div>
      </div>
      {ratingModified && <div className="text-xs break-words">{ratingModifiedReason}</div>}
      <div className="p-2 bg-white">{getDistinctImpactAlerts(alerts)?.length} flags raised</div>
      {getDistinctImpactAlerts(alerts)?.length > 0 && (
        <table className="w-full text-xs border-separate table-auto border-spacing-y-2">
          <thead>
            <tr className="font-normal uppercase text-slate-400">
              <th className="mb-2 font-normal text-left">flags</th>
              <th className="font-normal">total raised</th>
              <th className="font-normal">threshold</th>
            </tr>
          </thead>
          <tbody>
            {getDistinctImpactAlerts(alerts).map((alert: any) => (
              <tr key={alert.id} className="bg-white">
                <td className="py-4 px-2.5 mt-2">
                  <p>{alert.description}</p>
                  <span className="text-green-800">{alert.addedCount} Added</span>
                  <span className="ml-1 text-red-600">{alert.removedCount} Removed</span>
                </td>
                <td className="text-center">{alert.totalCount}</td>
                <td className="text-center">{alert.threshold || '--'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ProviewIndex;
