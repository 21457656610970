import { useState } from 'react';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import dayjs from 'dayjs';
import Accordion from 'common/components/Accordion';
import RecalculateIndex from 'common/components/RecalculateIndex';
import SystemInfo from 'components/summary/SystemInfo';
import ProviewIndex from 'components/summary/ProviewIndex';
import CandidateInfo from 'components/summary/CandidateInfo';
import { useGetInstructionsQuery } from 'common/api/proctor';
import { useGetPostSessionQuery } from 'common/api/post-session-embed';
import { getSessionCompletedTimestamp } from 'helpers/playback';
import { formatDate } from 'helpers/date';
import MarkReview from 'common/components/MarkReview';
import { AlertTypes, SessionScope } from 'globals/enums';
import { useAppSelector } from 'app/hooks';
import ApprovalList from './ApprovalList';
import { DeviceInfo } from './types';

const SummaryTab = ({ session_uuid, event_id, slot_id }) => {
  const [openAccordion, setOpenAccordion] = useState('');
  const { data } = useGetInstructionsQuery({ event_id, slot_id });
  const isEmbeddedView = useAppSelector((state) => state.playbackVideo.isEmbeddedView);
  const { data: sessionData } = useGetPostSessionQuery({ session_uuid });
  const { session_uuid: isPlaybackview } = useParams();

  const getLatestSystemInfoByType = (systemInfo: DeviceInfo[], alertTypeId: number) => {
    if (!systemInfo?.length) {
      return null;
    }

    const filteredSystemInfo = systemInfo.filter((system) => system.alert_type_id === alertTypeId);

    if (!filteredSystemInfo.length) {
      return null;
    }

    return filteredSystemInfo.reduce((latest, current) =>
      new Date(current.timestamp) > new Date(latest.timestamp) ? current : latest,
    );
  };

  const primaryDeviceInfo: DeviceInfo = getLatestSystemInfoByType(
    sessionData?.system_info,
    AlertTypes.SessionJoined,
  );
  const secondaryDeviceInfo: DeviceInfo = getLatestSystemInfoByType(
    sessionData?.system_info,
    AlertTypes.SecondaryDeviceJoined,
  );

  const getCleanHtml = (instructions: string) => {
    const instructionHtml = DOMPurify.sanitize(instructions, { FORBID_ATTR: ['style'] });
    return <div dangerouslySetInnerHTML={{ __html: instructionHtml }}></div>;
  };

  const getExamWindow = () => {
    if (sessionData?.session_starts_at && sessionData?.session_ends_at) {
      const examTime = `${dayjs(sessionData.session_starts_at).format('HH:mm')} - ${dayjs(
        sessionData.session_ends_at,
      ).format('HH:mm')}`;
      const examWindow = `${dayjs(sessionData?.session_starts_at).format(
        'DD MMM YYYY',
      )}, ${examTime}`;
      return examWindow;
    }
  };

  const handleAccordionClick = (value: string) =>
    setOpenAccordion((prev) => (prev === value ? '' : value));

  return ( sessionData && (
    <div className="flex flex-col h-full overflow-auto">
      <div className="p-2">
        <Accordion
          title="Candidate Information"
          content={
            <CandidateInfo
              firstName={sessionData?.attendee?.first_name}
              lastName={sessionData?.attendee?.last_name}
              email={sessionData?.attendee?.email}
              examWindow={getExamWindow()}
              joinTime={
                sessionData?.alerts?.length && formatDate(sessionData?.alerts[0]?.timestamp)
              }
              completionTime={getSessionCompletedTimestamp(sessionData?.alerts)}
            />
          }
          isOpen={openAccordion === 'candidate_information'}
          onClick={() => handleAccordionClick('candidate_information')}
        />
        <Accordion
          title="System Information"
          content={
            <SystemInfo
              os={primaryDeviceInfo?.os}
              os_version={primaryDeviceInfo?.os_version}
              browser={primaryDeviceInfo?.browser}
              browser_version={primaryDeviceInfo?.browser_version}
            />
          }
          isOpen={openAccordion === 'system_information'}
          onClick={() => handleAccordionClick('system_information')}
        />
        {isPlaybackview && (
          <Accordion
            title="Approvals"
            content={sessionData && <ApprovalList session={sessionData} />}
            isOpen={openAccordion === 'approvals'}
            onClick={() => handleAccordionClick('approvals')}
          />
        )}
        <Accordion
          title="Candidate Instructions"
          content={getCleanHtml(data?.candidate_instructions)}
          isOpen={openAccordion === 'candidate_instructions'}
          onClick={() => handleAccordionClick('candidate_instructions')}
        />
        <Accordion
          title="Proctor Instructions"
          content={getCleanHtml(data?.proctor_instructions)}
          isOpen={openAccordion === 'proctor_instructions'}
          onClick={() => handleAccordionClick('proctor_instructions')}
        />
        <Accordion
          title="Proview Index"
          content={
            <ProviewIndex
              ratingModified={sessionData?.rating_modified}
              ratingModifiedAt={sessionData?.rating_modified_at}
              ratingModifiedReason={sessionData?.rating_modified_reason}
              sessionID={sessionData?.session_id}
              alerts={sessionData?.alerts}
            />
          }
          isOpen={openAccordion === 'proview_index'}
          onClick={() => handleAccordionClick('proview_index')}
        />
      </div>
      {openAccordion === 'proview_index' && !isEmbeddedView && (
        <div className="flex w-full mt-auto bg-white justify-evenly h-fit">
          <RecalculateIndex sessionType={sessionData?.session_type} />
          {sessionData?.session_type === SessionScope.RecordAndReview && (
            <MarkReview sessionId={sessionData?.session_id} />
          )}
        </div>
      )}
    </div>
  ));
};

export default SummaryTab;
