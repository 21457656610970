import React from 'react';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';

interface CardProps {
  title: string;
  icon: React.ReactNode;
  fields: { id: string; label: string; value: any }[];
  formatValue: (value: any) => React.ReactNode;
  handleCopy?: (value: string) => void;
}

const Card: React.FC<CardProps> = ({ title, icon, fields, formatValue, handleCopy }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-6">
      <h3 className="text-xl font-semibold mb-4 text-gray-800 flex items-center">
        {icon}
        {title}
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {fields.map((field) => (
          <div key={field.id} className="flex items-center">
            <div className="text-sm font-medium text-gray-800">{field.label}:</div>
            <div className="ml-2 text-sm text-gray-800">{formatValue(field.value)}</div>
            {handleCopy && field.value && (
              <button
                data-testid="copy-button"
                onClick={() => handleCopy(field.value as string)}
                className="ml-2 text-blue-600 hover:text-blue-800"
              >
                <DocumentDuplicateIcon className="h-5 w-5" />
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
